import styled from 'styled-components';
import { Row, Container, Col, Carousel } from 'react-bootstrap';
import { Link } from 'gatsby';

export const DeliveringMoreContainer = styled.div`
  background: linear-gradient(
    to bottom,
    rgba(0, 166, 232, 1) 0%,
    rgba(0, 83, 157, 1) 100%
  );
  overflow: hidden;
`;
export const DeliveringMoreInnerContainer = styled(Container)`
  color: #fff;
  margin: auto;
  padding: 10px;
  align-content: center;
`;

export const DeliveringTitle = styled.div`
  display: block;
  letter-spacing: -0.01em;
  color: #ffffff;
  text-align: center;
  font-family: 'MrAlex', arial, sans-serif;
  margin-bottom: 55px;
  font-size: 45px;
  line-height: 45px;
`;
export const AnchorStyle = styled.a`
  color: #ffc905;
  text-decoration: none;
  padding: 0 15px;
  font-size: 20px;
  line-height: 20px;
  cursor: pointer;
  -moz-transition: color 0.15s ease-out;
  -o-transition: color 0.15s ease-out;
  -ms-transition: color 0.15s ease-out;
  transition: color 0.15s ease-out;
  :hover {
    color: #f7991c;
  }

  font-family: 'MrAlex', arial, sans-serif;
  font-size: 25px;
  line-height: 25px;
  color: #ffc905;
  text-transform: uppercase;
`;

export const Column = styled(Col)`
  padding-right: 10px;
  padding-left: 30px;
  text-align: center;
  align-content: center;
  overflow-x: hidden;
`;

export const ColumnImage = styled(Col)`
  text-align: center;
  align-content: center;
`;

export const CarouselTemplate = styled(Carousel)`
  text-align: center;
  align-content: center;
`;

export const DeliveringContainer = styled(Container)`
  padding: 75px 10px 40px 10px;
  display: block;
`;

export const ImageStyle = styled.img`
  display: block;
  width: auto;
  height: 100px;
  margin: 2px auto 15px auto;
  transition: all 0.25s ease-out;
  :hover {
    transform: scale(1.15);
  }
`;

export const ImageEagleStyle = styled.img`
  display: block;
  width: 90%;
  height: auto;
  margin: 0 auto 5px auto;
`;

export const DeliveringMoreClearFix = styled.div`
  padding-bottom: 85px;
  background: rgb(0, 166, 232); /* Old browsers */
  background: -moz-linear-gradient(
    top,
    rgba(0, 166, 232, 1) 0%,
    rgba(0, 83, 157, 1) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(0, 166, 232, 1) 0%,
    rgba(0, 83, 157, 1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(0, 166, 232, 1) 0%,
    rgba(0, 83, 157, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  overflow: hidden;
  width: 100%;
  :after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: ' ';
    clear: both;
    height: 0;
  }
`;

export const DeliveringMoreEagle = styled.div`
  float: right;
  width: 45%;
  img {
    width: 90%;
    height: auto;
  }
  @media (max-width: 1439px) {
    float: right;
    width: 45%;
  }
  @media (max-width: 1023px) {
    float: none !important;
    width: 100% !important;
    text-align: center;
    max-width: 400px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 0;
    margin: 0 auto;
  }
  @media (max-width: 767px) {
    padding-left: 50px;
    padding-right: 50px;
    float: none;
    width: 100%;
  }
`;
export const DeliveringMoreProducts = styled.div`
  float: right;
  width: 55%;
  padding: 125px 0 0 0;
  text-align: center;
  @media (max-width: 1439px) {
    padding-top: 75px;
  }
  @media (max-width: 1023px) {
    float: none;
    width: 100%;
    padding-top: 50px;
  }
  @media (max-width: 767px) {
    padding-top: 45px;
    padding-bottom: 0;
  }
`;
export const DeliveringMoreProductsHeading = styled.h2`
  position: relative;
  margin: 0 0 35px 0;
  font-family: 'MrAlex', sans-serif;
  font-size: 45px;
  line-height: 50px;
  color: #fff;
  text-transform: uppercase;
  code {
    color: #ffc905;
    font-size: 45px;
    font-family: 'MrAlex', sans-serif;
  }
  @media (max-width: 1830px) {
    font-size: 65px;
    line-height: 65px;
    code {
      color: #ffc905;
      font-size: 65px;
      font-family: 'MrAlex', sans-serif;
    }
  }
  @media (max-width: 1439px) {
    margin-bottom: 55px;
    font-size: 35px;
    line-height: 35px;
    code {
      color: #ffc905;
      font-size: 35px;
      font-family: 'MrAlex', sans-serif;
    }
  }
  @media (max-width: 767px) {
    margin-bottom: 25px;
    font-size: 30px;
    line-height: 30px;
    code {
      color: #ffc905;
      font-size: 30px;
      font-family: 'MrAlex', sans-serif;
    }
  }
`;
export const DeliveringMoreProductList = styled.div`
  display: inline-block;
  width: 700px;
  font-size: 0;
  margin-left: -20px;
  @media (max-width: 1439px) {
    width: 500px;
    margin-left: 0;
  }
  @media (max-width: 1300px) {
    width: 450px;
  }

  @media (max-width: 767px) {
    width: 100%;
  }
`;
export const DeliveringMoreProductListItem = styled.a`
  text-decoration: none;
  text-transform: uppercase;
  :hover {
    color: #f7991c;
  }
  font-family: 'MrAlex', arial, sans-serif;
  font-size: 25px;
  line-height: 25px;
  color: #ffc905;
  cursor: pointer;
  margin: 0 30px;
  margin-bottom: 0;
  display: inline-block;
  @media (max-width: 1439px) {
    padding: 0;
    font-size: 25px;
    line-height: 20px;
    margin: 0;
    :nth-child(1) {
      width: 21%;
    }
    :nth-child(2) {
      width: 29%;
    }
    :nth-child(3) {
      width: 26%;
    }
    :nth-child(4) {
      width: 24%;
    }
  }
  @media (max-width: 1023) {
    font-size: 18px;
    line-height: 18px;
    margin: 0;
    padding: 0;
  }
  @media (max-width: 767px) {
    font-size: 15px;
    line-height: 15px;
    margin: 0;
    padding: 0;
    :nth-child(1) {
      width: 60px;
    }
    :nth-child(2) {
      width: 70px;
    }
    :nth-child(3) {
      width: 75px;
    }
    :nth-child(4) {
      width: 80px;
    }
  }
`;
export const AnchorText = styled.a`
  font-family: 'MrAlex';
  font-size: 25px;
  line-height: 25px;
  color: #ffc905;
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
  :hover {
    color: #f7991c;
  }
`;
export const DeliveringMoreProductListImage = styled.img`
  display: block;
  width: auto;
  height: 98px;
  margin: 0 auto 35px auto;
  transition: all 0.25s ease-out;
  :hover {
    transform: scale(1.15);
  }
  @media (max-width: 1439px) {
    height: 70px;
    margin: 0 auto 35px auto;
  }
  @media (max-width: 1023px) {
    height: 80px;
  }
  @media (max-width: 767px) {
    height: 45px;
    margin-bottom: 20px;
  }
`;
